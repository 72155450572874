import { useState } from 'react';
import { getWorkforceListV2, getWorkforceSearch } from 'utils/network/apis';
import { useDeepCompareEffect } from './useDeepCompareEffect';
import { WorkforceCompliance } from 'models';
import { ComplianceCaseStatus } from 'utils/constants';

export type WorkforceSearchStatus = 'idle' | 'loading' | 'success' | 'error';
export type WorkforceTerminationEligibility =
  | 'all'
  | 'is_critical'
  | 'opened'
  | 'not_eligible';

export type WorkforceWorkStatus =
  | 'all'
  | 'working_now'
  | 'today'
  | 'tomorrow'
  | 'later'
  | 'not_scheduled';

export type WorkforceSearchQuery = {
  max: number;
  skip: number;
  generic?: string;
  selectedHubs?: string[];
  selectedFeedbackLevels?: string[];
  selectedPerformanceGroups?: string[];
  selectedTerminationEligibility?: WorkforceTerminationEligibility;
  selectedEmploymentStatus?:
    | 'all'
    | 'in_probation'
    | 'limited_contract'
    | 'unlimited_contract'
    | 'termination_requested'
    | 'termination_approved'
    | 'terminated';
  selectedWorkStatus?: WorkforceWorkStatus;
  selectedCPH?: string;
  selectedChurnRisk?: string;
  sort?: string;
  from: string;
  to: string;
  excludeZeroNoShows?: boolean;
  excludeZeroLateShifts?: boolean;
  excludeZeroAcceptanceRate?: boolean;
  excludeZeroUtr?: boolean;
  excludeZeroAveragePickingTime?: boolean;
  excludeZeroPostOrderIssueRate?: boolean;
};

export type WorkforceSearchResponse = {
  status: WorkforceSearchStatus;
  error: string | null;
  data: {
    pagination?: { total?: number; max?: number; limit?: number };
    results?: WorkforceCompliance[];
  };
};

const useWorkforceV2 = (
  query: WorkforceSearchQuery
): WorkforceSearchResponse => {
  const [status, setStatus] = useState<WorkforceSearchStatus>('idle');
  const [data, setData] = useState<{
    pagination?: { total?: number; max?: number; limit?: number };
    results?: WorkforceCompliance[];
  }>({});
  const [error, setError] = useState(null);

  useDeepCompareEffect(() => {
    if (!query) {
      return;
    }

    const getRiders = async (): Promise<void> => {
      setData({});
      setStatus('loading');
      setError(null);

      try {
        const {
          max,
          skip,
          generic,
          selectedHubs,
          selectedTerminationEligibility,
          selectedEmploymentStatus,
          selectedFeedbackLevels,
          selectedPerformanceGroups,
          selectedWorkStatus,
          selectedCPH,
          selectedChurnRisk,
          sort,
          from,
          to,
          excludeZeroNoShows,
          excludeZeroLateShifts,
          excludeZeroAcceptanceRate,
          excludeZeroUtr,
          excludeZeroAveragePickingTime,
          excludeZeroPostOrderIssueRate,
        } = query;

        const caseStatus =
          selectedEmploymentStatus === 'termination_approved'
            ? ComplianceCaseStatus.TERMINATION_APPROVED
            : selectedEmploymentStatus === 'termination_requested'
            ? ComplianceCaseStatus.TERMINATION_REQUESTED
            : selectedTerminationEligibility === 'not_eligible'
            ? 'NOT_ELIGIBLE'
            : selectedTerminationEligibility === 'is_critical' ||
              selectedTerminationEligibility === 'opened'
            ? ComplianceCaseStatus.OPENED
            : '';

        const params = {
          limit: max,
          offset: skip,
          from_date: from,
          to_date: to,
          ...(generic ? { generic } : null),
          ...(sort ? { sort } : null),
          ...(selectedHubs ? { hub: selectedHubs } : null),

          ...(selectedEmploymentStatus === 'terminated'
            ? { is_terminated: true }
            : generic ||
              excludeZeroNoShows ||
              excludeZeroLateShifts ||
              excludeZeroAcceptanceRate ||
              excludeZeroUtr ||
              excludeZeroAveragePickingTime ||
              excludeZeroPostOrderIssueRate
            ? {}
            : { is_terminated: false }),

          ...(selectedEmploymentStatus === 'in_probation'
            ? { is_in_probation: true }
            : {}),

          ...(selectedEmploymentStatus === 'limited_contract'
            ? { is_limited_contract: true }
            : selectedEmploymentStatus === 'unlimited_contract'
            ? {
                is_limited_contract: false,
              }
            : {}),

          ...(caseStatus ? { case_status: caseStatus } : null),
          ...(selectedTerminationEligibility === 'is_critical'
            ? { is_critical: true }
            : selectedTerminationEligibility === 'opened'
            ? { is_critical: false }
            : null),
          ...(selectedFeedbackLevels
            ? { feedback_level: selectedFeedbackLevels }
            : null),
          ...(selectedPerformanceGroups
            ? { performance_group: selectedPerformanceGroups }
            : null),
          ...(selectedWorkStatus !== 'all'
            ? { work_status: selectedWorkStatus }
            : null),
          ...(selectedCPH && selectedCPH !== 'all'
            ? { age: `gte:${selectedCPH}` }
            : null),
          ...(selectedChurnRisk && selectedChurnRisk !== 'all'
            ? { churn_risk: selectedChurnRisk }
            : null),
          ...(excludeZeroNoShows
            ? { exclude_zero_no_shows: excludeZeroNoShows }
            : null),
          ...(excludeZeroLateShifts
            ? { exclude_zero_late_shows: excludeZeroLateShifts }
            : null),
          ...(excludeZeroAcceptanceRate
            ? { exclude_zero_acceptance_rate: excludeZeroAcceptanceRate }
            : null),
          ...(excludeZeroUtr ? { exclude_zero_utr: excludeZeroUtr } : null),
          ...(excludeZeroAveragePickingTime
            ? {
                exclude_zero_average_picking_time:
                  excludeZeroAveragePickingTime,
              }
            : null),
          ...(excludeZeroPostOrderIssueRate
            ? {
                exclude_zero_post_order_issue_rate:
                  excludeZeroPostOrderIssueRate,
              }
            : null),
        };

        const response = generic
          ? await getWorkforceSearch(params)
          : await getWorkforceListV2(params);

        setData({
          ...response,
          results: response.results.map(WorkforceCompliance.from),
        });
        setStatus('success');
      } catch (err: any) {
        setStatus('error');
        setError(
          err?.response?.status === 403
            ? 'error_permission_required'
            : err?.response?.data?.error || err?.message
        );
      }
    };

    getRiders();
  }, [query]);

  return { status, error, data };
};

export default useWorkforceV2;
