import { useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, Input, InputVariant } from '@hubportal/components';
import { Info } from 'components/info';
import { EC_ID_REGEX } from 'utils/helpers';
import { getInternalRider, importInternalRider } from 'utils/network/apis';
import { Confirmation } from './confirmation';
import { Account } from 'models';
import * as Yup from 'yup';

interface CreateInternalRiderProps {
  onSuccess(): void;
}

const initialValues = {
  ecId: '',
};

const getErrorMessage = (err: any): string => {
  const validationFields = Object.keys(err?.response?.data?.fields || {});
  const error = err?.response?.data?.error || '';
  const errorMessage = error ? error.concat(': ').concat(validationFields) : '';

  return err?.response?.status === 404
    ? 'rider_account_not_found_ec_id'
    : errorMessage || err.message;
};

const getValidationSchema = (t): object =>
  Yup.object({
    ecId: Yup.string()
      .matches(RegExp(EC_ID_REGEX), t('invalid_ec_id'))
      .required(t('required')),
  });

const CreateInternalRider = ({
  onSuccess,
}: CreateInternalRiderProps): JSX.Element => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<'createRider' | null>();
  const [account, setAccount] = useState<Account>();
  const [state, setState] = useState<null | {
    type: 'success' | 'error' | 'loading';
    message?: string;
  }>();

  const onSubmit = async ({
    name,
    surname,
    phone,
    email,
    country,
    hub,
    birthday,
  }): Promise<void> => {
    try {
      setState({ type: 'loading' });
      await importInternalRider({
        email,
        first_name: name,
        last_name: surname,
        phone_number: phone,
        country,
        hub_slug: hub,
        ec_id: account?.ecID,
        date_of_birth: birthday,
      });
      setState({ type: 'success', message: t('rider_account_imported') });
      onSuccess();
    } catch (err: any) {
      setState({ type: 'error', message: getErrorMessage(err) });
    }
  };

  const getRiderInfo = async (ecId): Promise<void> => {
    try {
      setState({ type: 'loading' });
      const response = await getInternalRider(ecId);
      setAccount(Account.from(response));
      setModal('createRider');
      setState(null);
    } catch (err: any) {
      setState({ type: 'error', message: getErrorMessage(err) });
    }
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
    },
    validateOnMount: true,
    validationSchema: getValidationSchema(t),
    onSubmit: async () => {
      formik.setSubmitting(true);
      await getRiderInfo(formik.values.ecId);
      formik.setSubmitting(false);
    },
  });

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        className="flex flex-col grow gap-4"
      >
        <Input
          required
          label={t('ec_id')}
          placeholder="00112233"
          variant={InputVariant.secondary}
          error={formik.touched.ecId ? formik.errors.ecId : ''}
          {...formik.getFieldProps('ecId')}
        />

        <div className="mt-auto">
          {(state?.type === 'error' || state?.type === 'success') &&
            state?.message && (
              <Info type={state?.type}>{t(state?.message)}</Info>
            )}
          <Button
            onClick={() => {
              formik.submitForm();
            }}
            disabled={
              !formik.isValid ||
              formik.isSubmitting ||
              state?.type === 'loading'
            }
            loading={formik.isSubmitting || state?.type === 'loading'}
          >
            {t('import_rider')}
          </Button>
        </div>
      </form>
      <Confirmation
        open={modal === 'createRider'}
        account={account as Account}
        onConfirm={async (data) => {
          setModal(null);
          await onSubmit(data);
        }}
        onClose={() => setModal(null)}
      />
    </>
  );
};

export { CreateInternalRider };
