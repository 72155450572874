import { useTranslation } from 'react-i18next';
import useHubs from 'utils/hooks/useHubs';
import { Select, SelectItem, SelectProps } from '@hubportal/components';

const HubsSelect = ({ onChange, ...props }: SelectProps): JSX.Element => {
  const { t } = useTranslation();
  const { data: hubs } = useHubs();

  return (
    <Select
      required
      searchable
      label={t('hub')}
      placeholder={t('hub')}
      onChange={onChange}
      {...props}
    >
      {hubs.map((hub) => {
        const { id } = hub;
        return (
          <SelectItem key={id} id={id} label={id}>
            {id}
          </SelectItem>
        );
      })}
    </Select>
  );
};

export { HubsSelect };
