import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { CountrySelect, HubsSelect } from 'partials/select';
import {
  BIRTHDAY_MASKED_DATE_OPTIONS,
  BIRTHDAY_REGEX,
  PHONE_NUMBER_REGEX,
} from 'utils/helpers';
import {
  Button,
  ButtonVariant,
  Input,
  InputVariant,
  MASKED_DATE_OPTIONS,
  Modal,
  SelectVariant,
} from '@hubportal/components';
import { Account } from 'models';
import omit from 'lodash.omit';
import * as Yup from 'yup';

type EditableRiderFields = {
  name: string;
  surname: string;
  phone: string;
  email: string;
  hub: { id: string; label: string };
  country: { id: string; label: string };
  birthday: string;
};

const initialValues: EditableRiderFields = {
  name: '',
  surname: '',
  phone: '',
  email: '',
  hub: { id: '', label: '' },
  country: { id: '', label: '' },
  birthday: '',
};

const getValidationSchema = (t): object =>
  Yup.object({
    name: Yup.string().required(t('required')),
    surname: Yup.string().required(t('required')),
    email: Yup.string().email(t('invalid_email')).required(t('required')),
    phone: Yup.string()
      .matches(RegExp(PHONE_NUMBER_REGEX), t('invalid_phone_number'))
      .required(t('required')),
    hub: Yup.object({
      id: Yup.string().required(t('required')),
      label: Yup.string(),
    }).required(),
    country: Yup.object({
      id: Yup.string().required(t('required')),
      label: Yup.string(),
    }).required(),
    birthday: Yup.string()
      .matches(RegExp(BIRTHDAY_REGEX), t('invalid_date'))
      .when('country', {
        is: ({ label }: { label: string }) => label === 'NL',
        then: (schema) => schema.required(t('required')),
      }),
  });

const Confirmation = ({
  open,
  account,
  onClose,
  onConfirm,
}: {
  open: boolean;
  account: Account | null;
  onClose: () => void;
  onConfirm: (fields: any) => Promise<void>;
}): JSX.Element => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      ...initialValues,
    },
    validateOnMount: false,
    validationSchema: getValidationSchema(t),
    onSubmit: async () => {
      formik.setSubmitting(true);
      await onConfirm({
        name: formik.values.name,
        surname: formik.values.surname,
        email: formik.values.email,
        phone: formik.values.phone,
        hub: formik.values.hub.id?.toLowerCase(),
        country: formik.values.country.id?.toUpperCase(),
        birthday: formik.values.birthday,
      });
      formik.setSubmitting(false);
    },
  });

  useEffect(() => {
    const hub = account?.hubSlug || '';
    const country = hub.slice(0, 2);
    formik.setValues(
      {
        ...formik.values,
        name: account?.firstName || '',
        surname: account?.lastName || '',
        phone: account?.cellPhone || '',
        email: account?.email || '',
        hub: { id: hub || '', label: hub || '' },
        country: {
          id: country.toLowerCase(),
          label: t(`countries_${country.toLowerCase()}`),
        },
        birthday: account?.dateOfBirth || '',
      },
      true
    );
  }, [account]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      header={t('import_internal_rider_confirmation')}
      footer={
        <div className="flex gap-2 justify-end my-8 mx-14">
          <Button variant={ButtonVariant.quinary} onClick={onClose}>
            {t('no')}
          </Button>
          <Button
            disabled={!formik.isValid || formik.isSubmitting}
            onClick={() => {
              formik.submitForm();
            }}
          >
            {t('yes')}
          </Button>
        </div>
      }
    >
      <>
        <form
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          className="flex flex-col grow gap-4"
        >
          <div>
            {t('ec_id')}: {account?.ecID}
          </div>

          <div className="flex gap-2">
            <Input
              required
              label={t('first_name')}
              placeholder={t('first_name')}
              variant={InputVariant.secondary}
              error={formik.touched.name ? formik.errors.name : ''}
              {...formik.getFieldProps('name')}
            />

            <Input
              required
              label={t('last_name')}
              placeholder={t('last_name')}
              variant={InputVariant.secondary}
              error={formik.touched.surname ? formik.errors.surname : ''}
              {...formik.getFieldProps('surname')}
            />
          </div>
          <HubsSelect
            variant={SelectVariant.secondary}
            error={formik.touched?.hub ? formik.errors?.hub?.id : ''}
            onChange={(value) => {
              const country = value?.id.toLowerCase().slice(0, 2);
              formik.handleChange({ target: { name: 'hub', value } });
              formik.handleChange({
                target: {
                  name: 'country',
                  value: {
                    id: country.toLowerCase(),
                    label: t(`countries_${country.toLowerCase()}`),
                  },
                },
              });
            }}
            {...omit(formik.getFieldProps('hub'), 'onChange')}
          />
          <CountrySelect
            disabled
            variant={SelectVariant.secondary}
            error={formik.touched.country ? formik.errors.country?.id : ''}
            onChange={(value) =>
              formik.handleChange({ target: { name: 'country', value } })
            }
            {...omit(formik.getFieldProps('country'), 'onChange')}
          />
          <Input
            required
            type="email"
            label={t('email')}
            placeholder="max@gmail.com"
            variant={InputVariant.secondary}
            error={(formik.errors.email as string) || ''}
            {...formik.getFieldProps('email')}
          />

          <Input
            required
            type="tel"
            label={t('phone_number')}
            placeholder="+491112223344"
            variant={InputVariant.secondary}
            error={(formik.errors.phone as string) || ''}
            {...formik.getFieldProps('phone')}
          />

          <Input
            required={formik.values.country.label === 'NL'}
            label={t('date_of_birth')}
            placeholder="2001-08-31"
            maskOptions={{
              ...MASKED_DATE_OPTIONS,
              ...BIRTHDAY_MASKED_DATE_OPTIONS,
            }}
            variant={InputVariant.secondary}
            error={formik.touched.birthday ? formik.errors.birthday : ''}
            {...formik.getFieldProps('birthday')}
          />
        </form>
      </>
    </Modal>
  );
};

export { Confirmation };
