import React from 'react';
import classnames from 'classnames';
import { Icon } from '@hubportal/components';

const INFO_CLASS =
  'my-2  detail-m rounded flex items-center gap-2 max-w-max px-2';

interface InfoProps {
  type?: 'success' | 'error';
  variant?: 'primary' | 'secondary';
}

const Info = ({
  type = 'success',
  variant = 'primary',
  children,
}: React.PropsWithChildren<InfoProps>): JSX.Element => {
  return (
    <div
      className={classnames(
        INFO_CLASS,
        variant === 'secondary' ? 'bg-secondary' : 'bg-primary'
      )}
    >
      <span className="text-pink">
        <Icon type={type === 'success' ? 'check' : 'close'} />
      </span>
      {children}
    </div>
  );
};

export { Info };
