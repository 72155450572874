export class Account {
  ecID?: string;
  firstName: string;
  lastName: string;
  email: string;
  cellPhone: string;
  hubSlug: string;
  dateOfBirth: string;

  constructor(details: any = {}) {
    this.ecID = details.ec_id;
    this.firstName = details.first_name;
    this.lastName = details.last_name;
    this.email = details.email;
    this.cellPhone = details.cell_phone;
    this.hubSlug = details.hub_slug;
    this.dateOfBirth = details.date_of_birth;
  }

  public static from(details: any): Account {
    return new Account(details);
  }
}
